@import url('https://fonts.googleapis.com/css2?family=Pixelify+Sans:wght@400..700&display=swap');
body {
    background-color: #090a15;
}

h1 {
    color: #61dafb;
    font-size: 80px;
    font-family: "Pixelify Sans", sans-serif;
    font-optical-sizing: auto;
    text-align: center;
}

h2 {
    background-image: linear-gradient(45deg, #ffffff, #eca7ff);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 30px;
    text-align: center;
    margin-bottom: 50px;
    font-style: italic;
}

h3 {
    background-image: linear-gradient(45deg, #ffffff, #eca7ff);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 20px;
    margin-bottom: 50px;
}


footer {
    font-size: 13px;
    position: absolute;
    bottom: 0;
    width: 100%;
}

footer p {
    color: rgba(203, 203, 203, 0.63);
    text-align: center;
}




