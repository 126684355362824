.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popup-content {
    position: relative;
    background-color: #090a15;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 15px #00f0ff;
    border: 2px solid #00f0ff;
    color: white;
    text-align: center;
    max-width: 400px;
    width: 100%;
}

.close-button {
    position: absolute;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
    color: #61dafb;
    font-size: 24px;
    cursor: pointer;
    transition: transform 0.2s;
}

.close-button:hover {
    transform: scale(1.1);
}

h2, p {
    margin: 0;
    color: white;
}

