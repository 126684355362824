@import url(https://fonts.googleapis.com/css2?family=Pixelify+Sans:wght@400..700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    background-color: #090a15;
}

h1 {
    color: #61dafb;
    font-size: 80px;
    font-family: "Pixelify Sans", sans-serif;
    font-optical-sizing: auto;
    text-align: center;
}

h2 {
    background-image: linear-gradient(45deg, #ffffff, #eca7ff);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 30px;
    text-align: center;
    margin-bottom: 50px;
    font-style: italic;
}

h3 {
    background-image: linear-gradient(45deg, #ffffff, #eca7ff);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 20px;
    margin-bottom: 50px;
}


footer {
    font-size: 13px;
    position: absolute;
    bottom: 0;
    width: 100%;
}

footer p {
    color: rgba(203, 203, 203, 0.63);
    text-align: center;
}





.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popup-content {
    position: relative;
    background-color: #090a15;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 15px #00f0ff;
    border: 2px solid #00f0ff;
    color: white;
    text-align: center;
    max-width: 400px;
    width: 100%;
}

.close-button {
    position: absolute;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
    color: #61dafb;
    font-size: 24px;
    cursor: pointer;
    transition: transform 0.2s;
}

.close-button:hover {
    transform: scale(1.1);
}

h2, p {
    margin: 0;
    color: white;
}


